/* Tools */
@mixin border-radius($radius){
    border-radius: $radius;
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
}

@mixin ellipsis(){
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin box-shadow($box-shadow){
    -moz-box-shadow:$box-shadow;
    -webkit-box-shadow:$box-shadow;
    box-shadow:$box-shadow;
}