/* Colors */
$primary: #7e57c2;

/* Media queries */
/* Extra small devices (phones, 600px and down) */
@mixin media-screen-xs(){
    @media screen and (max-width: 600px){
        @content;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@mixin media-screen-sm(){
    @media screen and (min-width: 600px){
        @content;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@mixin media-screen-md(){
    @media screen and (min-width: 768px){
        @content;
    }
}
/* Large devices (laptops/desktops, 992px and up) */
@mixin media-screen-lg(){
    @media screen and (min-width: 992px){
        @content;
    }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@mixin media-screen-xl(){
    @media screen and (min-width: 1200px){
        @content;
    }
}