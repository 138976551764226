@import './variables.scss';
@import './mixins.scss';

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

html{
    body{
        background-color: #eee;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 14px;
        line-height: 1.428571429;

        #root{
            padding-top: 60px; 
            .container{
                max-width: 970px;
                padding: 0 15px;
                margin: 0 auto;
            }
    
            header{
                z-index: 9999;
                background-color: #222;
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
    
                a{
                    color: #777;
                    text-decoration: none;
                    &:hover{
                        color: #fff;
                    }
                }
    
                h1{
                    display: inline-block;
                    line-height: 2.5rem;
                    font-size: 1.3rem;
                }
    
                .other-sites{
                    float: right;
                    display: inline-block;
                    margin-left: 20px;
                    button.text{
                        display: none;
                        background: none;
                        border: none;
                        color: #777;
                        cursor: pointer;
                        position: relative;
                        
                        &:hover{
                            color: #fff;
                        }
                        
                        svg{
                            margin-left: 5px;
                            font-size: 12px;
                        }
                    }
    
                    button.icon{
                        background: none;
                        border: none;
                        font-size: 1.3rem;
                        line-height: 40px;
                        cursor: pointer;
                        svg{
                            color: #777;
                        }
    
                        &:hover{
                            svg{
                                color: #fff;
                            }
                        }
                    }
    
                    @include media-screen-sm{
                        float: none;
                        button.text{
                            display: inline-block;
                        }
                        button.icon{
                            display: none;
                        }
                    }
        
                    ul{
                        z-index: 9999;
                        display: none;
                        position: fixed;
                        top: 40px;
                        left: 0;
                        right: 0;
                        background: #222;
                        padding: 5px 0;
                        border-top: 1px solid transparent;
                        @include box-shadow(inset 0 1px 0 rgba(255,255,255,.1));
                        li{
                            a{
                                padding: 7px 15px;
                                display: block;
    
                                h2{
                                    color: #fff;
                                    font-size: 14px;
                                    margin-bottom: 3px;
                                }
    
                                span{
                                    white-space: nowrap;
                                    font-size: 13px;
                                }
    
                                &:hover{
                                    background-color: #393939;
                                    span{
                                        color: #777;
                                    }
                                }
                            }
                        }
    
                        @include media-screen-sm{
                            position: absolute;
                            left: auto;
                            right: auto;
                            top: 100%;
                            @include box-shadow(none);
                            border-color: #101010;
                        }
                    }
    
                    &.open{
                        button.icon{
                            svg{
                                color: #fff;
                            }
                        }
                        button.text{
                            color: #fff;
                            background-color: #080808;
                        }
                        ul{
                            display: block;
                        }
                    }
                }
            }

            article{
                border: 1px solid #dcdcdc;
                background: #fff;
                @include border-radius(3px);
                padding: 10px 25px;

                &.welcome{
                    text-align: center;
                    h1, h2{
                        max-width: 410px;
                        margin: 0 auto;
                    }

                    h1{
                        font-size: 20px;
                        font-weight: bold;
                        line-height: 30px;
                        margin-bottom: 20px;
                    }

                    h2{
                        margin-bottom: 15px;
                    }

                    button.primary{
                        font-size: 18px;
                        margin-top: 10px;
                        margin-bottom: 20px;
                    }
                }

                &.questionnaire{
                    img.logo{
                        margin: 50px 0 20px;
                    }

                    h3, label{
                        font-size: 14px;
                        line-height: 2;
                    }

                    h3{
                        margin-top: 15px;
                        font-weight: bold;
                        small{
                            font-weight: 400;
                            font-style: italic;
                        }
                    }

                    select{
                        width: 220px;
                        height: 34px;
                        padding: 6px 12px;
                        font-size: 14px;
                        line-height: 2;
                        color: #555;
                        background-color: #fff;
                        background-image: none;
                        border: 1px solid #ccc;
                        @include border-radius(4px);
                        @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
                        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
                    }

                    input[type=text]{
                        width: 220px;
                        padding: 5px 10px;
                        font-size: 14px;
                        line-height: 1.4;
                    }

                    ol.terms{
                        padding-left: 30px;
                        li{
                            list-style: decimal;
                        }
                        i{
                            font-style: italic;
                        }
                    }

                    .nav-buttons{
                        margin-top: 10px;
                        overflow: hidden;
                        .next{
                            float: right;
                        }
                    }

                    .progress{
                        overflow: hidden;
                        height: 20px;
                        margin-bottom: 20px;
                        background-color: #f5f5f5;
                        @include border-radius(4px);
                        @include box-shadow(inset 0 1px 2px rgba(0,0,0,.1));

                        .progress-bar{
                            float: left;
                            width: 0%;
                            height: 100%;
                            font-size: 12px;
                            line-height: 20px;
                            color: #fff;
                            text-align: center;
                            background-color: $primary;
                            @include box-shadow(inset 0 -1px 0 rgba(0,0,0,.15));
                            transition: width .6s ease;
                        }
                    }

                    .slider{
                        height: 13px;
                        background-color: #f5f5f5;
                        @include border-radius(4px);
                        @include box-shadow(inset 0 1px 2px rgba(0,0,0,.1));
                        position: relative;
                        margin: 5px 30px 70px;
                        cursor: pointer;
                        .marker{
                            position: absolute;
                            z-index: 2;
                            width: 20px;
                            height: 20px;
                            top: -4px;
                            margin-left: -10px;
                            border-color: 1px solid rgba(0,0,0,.1) rgba(0,0,0,.1) rgba(0,0,0,.25);
                            background: linear-gradient(to bottom, lighten($primary, 5%), darken($primary, 5%));
                            @include border-radius(3px);
                        }
                        ul{
                            position: absolute;
                            top: 22px;
                            width: 100%;
                            li{
                                position: absolute;
                                .line{
                                    display: block;
                                    height: 4px;
                                    border-left: 1px solid #999;
                                }

                                .text{
                                    width: 80px;
                                    text-align: center;
                                    margin-left: -40px;
                                    color: #999;
                                }

                                &:nth-child(1){
                                    left: 0;
                                }
                                &:nth-child(2){
                                    left: 11.1111%;
                                }
                                &:nth-child(3){
                                    left: 22.2222%;
                                }
                                &:nth-child(4){
                                    left: 33.3333%;
                                }
                                &:nth-child(5){
                                    left: 44.4444%;
                                }
                                &:nth-child(6){
                                    left: 55.5555%;
                                }
                                &:nth-child(7){
                                    left: 66.6666%;
                                }
                                &:nth-child(8){
                                    left: 77.7777%;
                                }
                                &:nth-child(9){
                                    left: 88.8888%;
                                }
                                &:nth-child(10){
                                    left: 100%;
                                }
                            }
                        }
                    }

                    .result{
                        #graph{
                            text-align: center;
                            margin-top: 20px;
                        }
                    }
                }

                img.logo{
                    display: block;
                    height: auto;
                    width: 90%;
                    max-width: 100px;
                    margin: 50px auto 30px;
                }

                button.primary{
                    background: $primary;
                    border: none;
                    @include border-radius(5px);
                    padding: 10px 16px;
                    color: #fff;
                    cursor: pointer;

                    &:hover{
                        background: darken($primary, 10%);
                    }
                }
            }
        }
    }
}